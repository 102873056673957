import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";

import { Link } from "react-router-dom";
import { useEffect } from "react";
import pressRelease from "../../assests/PRESSRELEASE.jpg";


function Press() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      <section className="pressbanner">

      </section>

      <section className="mb-4" style={{ minHeight: '80vh' }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 text-center">
              <h4 class="text-center text-dark fw-bold mt-5 mb-0">
                PRESS RELEASES
              </h4>

            </div>
          </div>

          <div className="container">
            <h5 class="text-center text-dark fw-bold mt-3 mb-3">Love Without Reason Celebrates, Thursday, August 22, 1000 Surgeries!</h5>
            <div className="row ">
              <div className="col-md-6 ">

                <p style={{ textAlign: 'justify' }}>August 21, 2024 – Chattanooga, Tenn- Love Without Reason celebrates crossing the 1000th free cleft surgery mark! A reception open to the public will take place at the Oak Coffee House, located at <a className="text-decoration-none text-danger fw-bold " href="https://www.google.com/maps/search/2916+Silverdale+Road?entry=gmail&source=g">2916 Silverdale Road  </a>  in Chattanooga beginning at 5:30 PM, tomorrow, Thursday, August 22, 2024. Co-founders Santhosh and Susan Mathews will welcome donors, and friends of the ministry, and have a short presentation on their journey to providing 1000 smiles!</p>
                <p style={{ textAlign: 'justify' }}>“It is our mission to carry out our son <a className="text-decoration-none text-danger fw-bold " href="https://www.lovewithoutreason.org/Philip/">Philip’s dream </a>to work ‘Until the World is Cleft Free!’ and ‘no one is sold’.” Our needs to continue to be craniofacial surgeries and Human Trafficking Awareness Ministry here in the United States, in India, Kenya, Uganda, Zimbabwe, and allow us to expand into Tanzania,” states Chief Executive Officer and President of Love Without Reason, Santhosh Mathews. “Below are some of the numbers to show the work of the past seventeen years.</p>
              </div>
              <div className="col-md-6 text-center">

                <a href="https://www.local3news.com/local-news/chattanooga-nonprofit-celebrates-1000-surgeries-for-cleft-lip-awarded-day-of-recognition/article_699df194-6102-11ef-9e63-4369a146cb5d.html"><img className=" img-fluid" src={pressRelease} /></a>
                <button
                  className="btn btn-danger fw-bolder mt-3 mb-3"
                  onClick={() => window.location.href = 'https://www.local3news.com/local-news/chattanooga-nonprofit-celebrates-1000-surgeries-for-cleft-lip-awarded-day-of-recognition/article_699df194-6102-11ef-9e63-4369a146cb5d.html'}
                >
                  Read More
                </button>
              </div>
            </div>

            <h6 class=" text-dark fw-bold  mb-3"> Highlights:</h6>
            <ul>
              <li> <i class="fa fa-caret-right me-2 text-danger" aria-hidden="true"></i>Over 1000 free cleft and craniofacial surgeries provided in four countries and counting.</li>
              <li> <i class="fa fa-caret-right me-2 text-danger" aria-hidden="true"></i>Over 200,000 people were educated on the link between human trafficking and facial differences in three continents.</li>
              <li><i class="fa fa-caret-right me-2 text-danger" aria-hidden="true"></i>Over 300 women and children rescued from sex trafficking.</li>
              <li><i class="fa fa-caret-right me-2 text-danger" aria-hidden="true"></i>Over 1000 Set Fee Pads distributed for free to women in vulnerable circumstances in four countries.</li>
              <li><i class="fa fa-caret-right me-2 text-danger" aria-hidden="true"></i> 6000 women in red-light areas are reached with the message of hope.</li>
            </ul>
            <p>For more information on this event or Love Without Reason visit <a className="text-decoration-none text-danger fw-bold " href="https://lovewithoutreason.org/"> LoveWithoutReason.org.</a>  </p>
            <p>About Love Without Reason Founded in 2007 by Santhosh and Susan Mathews in Chattanooga,Tennessee. Love Without Reason is a nonprofit organization serving in the United States and six

              countries transforming the faces of those born with cleft and craniofacial differences and transforming the hearts of those rescued from human trafficking. The late Philip Mathews’ dream

              was to work “Until the World is Cleft Free and Until there are No More Sold.” The Mathews are committed to seeing Philip’s Dream fulfilled.</p>

          </div>
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-12 ">
            <h5 class="text-danger  fw-bold">
                <a
                  className="text-decoration-none text-danger"
                  href="https://youtu.be/d8rTNgLnx2Y?si=dniHxM1EwtNWRvwF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                   1. News Release about the camp in Garissa,  Kenya.  </a>
              </h5>
              <h5 class="text-danger  fw-bold">
                <a
                  className="text-decoration-none text-danger"
                  href="https://www.local3news.com/community/local-3-plus-you/3-plus-your-community--love-without-reason/video_a09ec4c3-7db6-5e11-ab65-c9f1c66ba891.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                   2. News article about the camp in Garissa. </a>
              </h5>
              <h5 class="text-danger  fw-bold">
                <a
                  className="text-decoration-none text-danger"
                  href="https://www.kenyanews.go.ke/blessings-in-disguise-father-sponsors-free-surgeries-after-sons-cleft-lip/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                   3. 3 Plus Your Community- Love Without Reason </a>
              </h5>


              <h5 class="text-danger  fw-bold mt-4">
                <a
                  className="text-decoration-none text-danger"
                  href="https://www.local3news.com/local-news/love-without-reason-hosts-tenth-annual-run4love-5k-10k-and-big-wheel-race/article_0b68beca-706f-11ef-90ec-3bcb1df00436.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  4. Love Without Reason hosts tenth annual 'Run4Love' 5k, 10k, and Big Wheel Race </a>
              </h5>


              <h5 class="text-danger  fw-bold mt-4">
                <a
                  className="text-decoration-none text-danger"
                  href="https://www.local3news.com/local-news/chattanooga-nonprofit-celebrates-1000-surgeries-for-cleft-lip-awarded-day-of-recognition/article_699df194-6102-11ef-9e63-4369a146cb5d.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  5. Chattanooga nonprofit celebrates 1000 surgeries for cleft lip, awarded day of recognition </a>
              </h5>


              <h5 class="text-danger  fw-bold mt-4">
                <a
                  className="text-decoration-none text-danger"
                  href="https://www.local3news.com/local-news/conditions-in-kenya-impact-chattanooga-nonprofits-mission/article_5e84e554-0736-11ef-8674-83b92ba2949d.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  6. Conditions in Kenya impact Chattanooga nonprofit's mission May 2, 2024                 </a>
              </h5>

              <h5 class="text-danger  fw-bold mt-4">
                <a
                  className="text-decoration-none text-danger"
                  href="https://www.local3news.com/local-news/love-without-reason-celebrates-16-years-of-free-craniofacial-surgeries/article_2a02f0e2-993a-11ee-bc70-9fba6ab7d455.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  7. Love Without Reason marks 16 years of offering free craniofacial surgeries  December 13, 2023
                </a>
              </h5>

              <Link to="/Press5k" className="text-decoration-none">
                <h5 class="text-danger fw-bold mt-4 ">
                  8. 5KRUN4LOVE  September 5, 2023
                </h5>
              </Link>

              <Link to="/Press2" className="text-decoration-none">
                <h5 class="text-danger fw-bold mt-4">
                  9. Love Without Reason announces the appointment of Christy Mathew as the chairwoman of the board June 2020
                </h5>
              </Link>

              <h5 class="text-danger mt-4 fw-bold">
                <a
                  className="text-decoration-none text-danger"
                  href="http://www.wrcbtv.com/story/35608025/eye-on-health-love-without-reason"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  10. Eye on health with Latrice Curry, WRCBTV news channel3  December 2, 2021
                </a>
              </h5>


              <h5 class="text-danger mt-4 fw-bold">
                <a className="text-danger text-decoration-none" href="https://wdef.com/2017/05/29/love-without-reason-helping-less-fortunate/" target="_blank" rel="noopener noreferrer">11. WDEF News Channel 12 May 29, 2017 </a>
              </h5>

              <h5 class="text-danger mt-4 fw-bold">
                <a className="text-decoration-none text-danger" href="http://www.timesfreepress.com/news/local/story/2017/may/27/chattanoogcouple-taking-cleft-palate-non-prof/430442/" target="_blank" rel="noopener noreferrer">12. Chattanooga Times Free Press article May 27, 2017 </a>
              </h5>

              <h5 class="text-danger mt-4 fw-bold">
                <a className="text-danger text-decoration-none" href="http://wutc.org/post/against-all-odds-love-without-reason-has-helped-hundreds-children-facing-almost-certain-death#stream/0" target="_blank" rel="noopener noreferrer">13. WUTC interview with Mr. Richard Winham</a>
              </h5>

              {/* <h5 class="text-danger mt-4 fw-bold">
                    <a className="text-decoration-none text-danger" href="http://www.timesfreepress.com/news/community/" target="_blank" rel="noopener noreferrer">6. LWR CELEBRATES 10 YEARS!</a>
                  </h5> */}

              <h5 class="text-danger mt-4 fw-bold">
                <a className="text-danger text-decoration-none" href="http://wutc.org/post/against-all-odds-love-without-reason-has-helped-hundreds-children-facing-almost-certain-death#stream/0" target="_blank" rel="noopener noreferrer">14. WDEF news channel 12, shine fashion show</a>
              </h5>


            </div>
          </div>
        </div>
      </section>



      <Footer />
    </>
  );
}

export { Press };
