import React from "react";
import line from "../../assests/Group 1870 line2.png";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
function Event5krun() {



  return (
    <>
      <Header />
      <section className=" Event5krun mt-5 d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      </section>

      <section style={{ minHeight: '100vh' }}>
        <div className="container">
          {/* <div className="row d-flex justify-content-center">
            <div className="col-md-5 mt-5 text-center">
              <a href="https://allevents.in/chattanooga/2024-race-to-the-dream-5k-10k-and-big-wheel-500/80003385385413" target="_blank" className="btn btn-danger p-2 px-3 fw-bolder">Register to run</a>
            </div>
            <div className="col-md-5 text-center mt-5">
              <Link to='/Event5kSponsorship' className="btn btn-danger p-2 fw-bolder px-3">Sponsorship Opportunities</Link>
            </div>
          </div> */}

          <div className="row d-flex justify-content-center mt-5 bg-light p-4 rounded india-text">
            <div className="col-md-10 text-center">
              <h3 className="fw-bolder">2025 RACE TO THE DREAM - 5k, 10k and Big Wheel 500</h3>

              <p className="mt-3"><span className="fw-bolder">WHAT:</span> Race to the Dream with a 5K 10K or Big Wheel Race</p>
              <p className="mt-3"><b> WHEN:</b> September 13 2025
                Walk-in Registration and Packet Pick-up from 8:00 A.M. - 9:00 A.M.
                Run begins at 9:30 A.M.</p>
              <p className="mt-3"> <b>WHERE:</b> Tennessee Riverpark Shelter #4 (Near Frye Center) 4301 Amnicola Hwy Chattanooga 37402.</p>
              <p className="mt-3"> <b> WHY:</b> September is Craniofacial Acceptance Month. Come and run in honor of all our cleft heroes around the globe!.</p>
            </div>
            <div className=" mt-3 text-center">
              <a
                href="https://allevents.in/chattanooga/5k10k-run4love-2025/80003374499940?ref=smdl"
                target="_blank"
                className="btn btn-danger  px-3 "
              >
                Register for 5K run 2025
              </a>
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-10">
              <h4 className="fw-bolder"> 10K and 5K Run</h4>
              <p className="india-text mt-3"> Do you love to run? Do you love to run in support of a great cause? Lace up those tennis shoes for the annual 5K Run4Love. Register today and choose which race you want to run - 10K or 5K – all alongside the scenic Tennessee River.</p>
              <h5 className="fw-bolder mt-3"> <i class="fa-solid fa-circle text-danger fs-6"></i>  10K Race $55</h5>
              <h5 className="fw-bolder"> <i class="fa-solid fa-circle text-danger fs-6"></i>  5K Race $35</h5>
              <h5 className="mt-3">Male and female divisions</h5>
              <h4 className="fw-bolder mt-5">Big Wheel Race</h4>

              <p className="india-text mt-2"> Remember the days of Big Wheel Races? You can relive them with your son, daughter, niece, or nephew. Bring your own Big Wheel and sign up for your age category race. Consent must be signed by parent/guardian at the time of the $10 registration.</p>

              <p className="mt-3 india-text">Registration will open in July, 2025!</p>
            </div>
          </div>
          {/* <div className="row d-flex justify-content-center">
            <div className="col-md-3 text-center mt-3 mb-4">
              <a href="https://allevents.in/chattanooga/2024-race-to-the-dream-5k-10k-and-big-wheel-500/80003385385413" target="_blank" className="btn btn-danger p-2 px-3 fw-bolder">Register to run</a>
            </div>
          </div> */}
        </div>
      </section>


      <Footer />
    </>
  );
}

export { Event5krun };
